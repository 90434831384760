import {SessionToken} from "@/lib/SessionManager";
import {AuthApi} from "@/api/auth";
import {RefreshResCode} from "@/lib/CommUtil";


export const AuthManager={
    async validateAuth() {
        // 토큰이 있는지 검사
        console.log('토큰이 있음')
        const result = await AuthApi.validateToken({token:SessionToken.getToken()})
        console.log('호출 완료')
        if( result.data.validated === true){
            console.log('유효한 토큰 입니다')
            return true
        }else{
            console.log('유효하지 않는 토큰 입니다 , 토큰 리프레스 요청을 합니다')
            const result = await AuthApi.refresh()
            console.log(result)
            if( result.code === "00"){
                return true
            }else if(result.code === RefreshResCode.REFRESH_INVALID_TOKEN_SESSION_MATCH ||
                result.code === RefreshResCode.REFRESH_STILL_TOKEN_REFRESHING
            ){
                console.log('재시도')
                const result = await AuthApi.refresh()
                console.log(result)
                if( result.code === "00"){
                    return true
                }
            }
            return false
        }
    },
    async validateAndRedirectLoginPage(){
        if( SessionToken.isLogin()){
            const ret = await this.validateAuth()
            if( ret  == false){
                console.log("로그인 페이지로 이동")
                SessionToken.clear()
                goForLogin()
            }else{
                console.log("인증 검증 성공")
                return true
            }
        }else{
            return true
        }

    },
    async logout(){
        // 로그 아웃에 대한 토큰 만료에 대한 처리가 필요하다
        if( SessionToken.isLogin()){
            await AuthApi.logout()
            SessionToken.clear()
        }
        goForLogin()
    }

}

export function goForLogin(){
    window.location.href=process.env.VUE_APP_WEBMAIN_HOST + '/auth/login'
}