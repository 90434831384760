import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import {SessionToken} from "@/lib/SessionManager";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authenticated: false,
        id: "",
        token: "",
        refreshToken:"",
        qaItem:null,
        myInfo:null,
    },
    getters: {
        getQnAItem(state){
            return state.qaItem
        },
        isAuthenticated(state){
            return state.authenticated
        },
        getId(state){
            return state.id
        },
        getMyInfo(state){
            return state.myInfo
        },
    },
    mutations: {
        login: (state, payload) => {
            state.authenticated = payload.authenticated;
            state.id = payload.id;
            state.token = payload.token;
            state.refreshToken = payload.refreshToken
            SessionToken.setSession({
                token: payload.token,
                refresh: payload.refreshToken,
            })
        },
        logout: (state) => {
          state.authenticated = false;
          state.id = "";
          state.token = "";
          state.refreshToken = "";
          SessionToken.clear()
        },
        setQnAItem:(state, payload) => {
            state.qaItem=payload
        },
        setMyInfo:(state, payload) => {
            state.myInfo=payload
        },
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            paths: [
                'authenticated',
                'id',
                'token',
                'refreshToken',
                'myInfo'
            ]
        })
    ]
})
